$osrit-font-sm: 14px;
$osrit-font-md: 14px;
$osrit-font-lg: 14px;
$osrit-input-height-sm: 28px;
$osrit-input-height-md: 35px;
$osrit-input-height-lg: 40px;

@mixin avatar($size, $inputHeight) {
  .documentX {
    .nav {
      .nav-item {
        .nav-link {
          font-size: $size;
        }
      }
    }

    .navbar-nav {
      a.nav-link {
        font-size: $size;
      }
    }

    .breadcrumb {
      .breadcrumb-item {
        font-size: $size;
      }
    }

    a.btn,
    button.btn {
      font-size: $size * 0.8;
    }

    h5 {
      font-size: $size;
    }

    .list-group {
      .list-group-item {
        font-size: $size;
      }
    }

    .toast {
      .toast-header {
        font-size: $size * 1.1;
      }

      .toast-body {
        font-size: $size;
      }
    }

    input.form-control,
    input.form-control-sm,
    select.form-control,
    select.form-control-sm {
      font-size: $size * 0.9;
      height: $inputHeight;
    }

    .osrit_date_widget {
      font-size: $size * 0.83;
    }

    label.form-label-sm {
      font-size: $size * 0.9;
    }

    input.form-control-sm {
      font-size: $size;
    }

    .card {
      .card-title {
        font-size: $size;
      }
    }

    #navAllApps {
      font-size: $size;
    }

    span.badge {
      font-size: $size * 0.8;
    }

    table.table,
    table.table.table-sm,
    table.table.table-striped {
      thead {
        th {
          font-size: $size * 0.9;
        }

        tr {
          font-size: $size * 0.9;

          th {
            font-size: $size * 0.9;
          }
        }
      }

      tbody {
        tr {
          font-size: $size * 0.9;

          th {
            font-size: $size * 0.9;
          }
        }

        td {
          font-size: $size * 0.9;

          a,
          div {
            font-size: $size * 0.9;
          }
        }
      }
    }

    a,
    div {
      font-size: $size;
    }

    dl.row {

      dd,
      dt {
        font-size: $size;
      }
    }

    .osrit_dispatch_data {

      dd,
      dt {
        font-size: $size;
      }
    }

    .osrit_more_button {
      font-size: $size * 0.8;
    }

    .modal-body,
    body,
    modal-dialog {
      font-size: $size;
    }

    .form-group {
      .osrit-inputbox-label {
        font-size: $size*2.5;
      }
    }
  }
}

#osritSmall {
  @include avatar($osrit-font-sm, $osrit-input-height-sm);
}

#osritMedium {
  @include avatar($osrit-font-md, $osrit-input-height-md);
}

#osritLarge {
  @include avatar($osrit-font-lg, $osrit-input-height-lg);
}